import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useId,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Node } from "reactflow";
import TriggerNodeEditor from "./TriggerNodeEditor";
import MoveConversationToCategoryEditor from "./MoveConversationToCategoryNodeEditor";
import SendWhatsappMessageNodeEditor from "./SendWhatsappMessageNodeEditor";
import SendWhatsappMediaNodeEditor from "./SendWhatsappMediaNodeEditor";
import AddTagToCustomerNodeEditor from "./AddTagToCustomerNodeEditor";
import EndWhatsappConversationNodeEditor from "./EndWhatsappConversationNodeEditor";
import SaveCustomerResponseNodeEditor from "./SaveCustomerResponseNodeEditor";

export interface DrawerNodeEditorProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveNode: (node: Node) => void;
  selectedNode: Node;
  onDeleteNode: (nodeId: string) => void;
}

const DrawerNodeEditor = ({
  isOpen,
  onClose,
  onSaveNode,
  selectedNode,
  onDeleteNode,
}: DrawerNodeEditorProps) => {
  const { t } = useTranslation();
  const formId = useId();

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose} size={"md"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {t(`enums.FlowNodeType.${selectedNode?.type}`) || null}
        </DrawerHeader>
        {selectedNode?.type === "send_whatsapp_message" ? (
          <SendWhatsappMessageNodeEditor
            data={selectedNode?.data}
            onSaveNode={onSaveNode}
            formId={formId}
          />
        ) : selectedNode?.type === "trigger" ? (
          <TriggerNodeEditor
            data={selectedNode?.data}
            onSaveNode={onSaveNode}
            formId={formId}
          />
        ) : selectedNode.type === "move_conversation_to_category" ? (
          <MoveConversationToCategoryEditor
            data={selectedNode.data}
            onSaveNode={onSaveNode}
            formId={formId}
          />
        ) : selectedNode.type === "send_whatsapp_media" ? (
          <SendWhatsappMediaNodeEditor
            formId={formId}
            data={selectedNode.data}
            onSaveNode={onSaveNode}
          />
        ) : selectedNode.type === "add_tag_to_customer" ? (
          <AddTagToCustomerNodeEditor
            formId={formId}
            data={selectedNode.data}
            onSaveNode={onSaveNode}
          />
        ) : selectedNode.type === "end_whatsapp_conversation" ? (
          <EndWhatsappConversationNodeEditor
            formId={formId}
            onSaveNode={onSaveNode}
          />
        ) : selectedNode.type === "save_customer_response" ? (
          <SaveCustomerResponseNodeEditor
            formId={formId}
            data={selectedNode.data}
            onSaveNode={onSaveNode}
          />
        ) : null}
        <DrawerFooter display="flex" justifyContent={"space-between"}>
          <Button
            variant="outline"
            type="button"
            onClick={() => onDeleteNode(selectedNode?.id)}
          >
            Excluir
          </Button>
          <Button variant="primary" type="submit" form={formId}>
            Salvar
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerNodeEditor;
