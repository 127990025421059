import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Text,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputNumber from "../../../../../components/InputNumber";
import TableFilters from "../../../../../components/TableFilters";
import { apiRoutes } from "../../../../../constants/api-routes";
import { appPaths } from "../../../../../constants/app-paths";
import { colors } from "../../../../../constants/colors";
import { MAX_CAMPAIGN_RECIPIENTS } from "../../../../../constants/max-campaign-recipients";
import { queryStringDelimiter } from "../../../../../constants/query-string-delimiter";
import { AudienceRecommendationsService } from "../../../../../services/audiece-recommendations.service";
import {
  finishCampaignCreation,
  setShowSelectCustomerRows,
} from "../../../../../state/campaignCreationSlice";
import { RootState } from "../../../../../state/store";
import { AudienceRecommendation } from "../../../../../types/AudienceRecommendation";
import { CustomerFiltersEnum } from "../../../../../types/CustomerFiltersEnum";
import { UrlUtils } from "../../../../../utils/url.utils";
import AudienceRecommendationSelector from "./AudienceRecommendationSelector";

const SectionCampaignRecipients = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { selectedCustomerRows, filterCriteria, selectedTemplate, variants } =
    useSelector((state: RootState) => state.campaignCreation);
  const selectedCustomerIds = Object.keys(selectedCustomerRows).slice(0, MAX_CAMPAIGN_RECIPIENTS);
  const dispatch = useDispatch();
  const [isFillingForm, setIsFillingForm] = useBoolean(false);
  const [minDaysSinceLastPurchase, setMinDaysSinceLastPurchase] = useState(Number(searchParams.get(CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE) || 21));
  const [minDaysSinceLastCampaign, setMinDaysSinceLastCampaign] = useState(Number(searchParams.get(CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN) || 14));
  const [selectedAudienceRecommendation, setSelectedAudienceRecommendation] =
    useState<AudienceRecommendation | null>(null);
  const shouldFinishCampaignCreationOnUnmountRef = useRef(true);

  const { data: audienceRecommendations } = useQuery(
    apiRoutes.listAudienceRecommendations(),
    async () => {
      const { data } =
        await AudienceRecommendationsService.listAudienceRecommendations();
      return data;
    }
  );

  useEffect(() => {
    shouldFinishCampaignCreationOnUnmountRef.current = true;
  }, []);

  useEffect(() => {
    return () => {
      if (shouldFinishCampaignCreationOnUnmountRef.current) {
        dispatch(finishCampaignCreation());
      }
    };
  }, [shouldFinishCampaignCreationOnUnmountRef, dispatch]);

  function handleClickSelectCustomers() {
    setIsFillingForm.on();
  }

  function handleClickEditSelectedCustomers() {
    shouldFinishCampaignCreationOnUnmountRef.current = false;
    dispatch(setShowSelectCustomerRows(true));
    const excludedTemplateIds = [
      selectedTemplate!.id,
      ...variants.map((variant) => variant.templateId),
    ].join(queryStringDelimiter);

    navigate({
      pathname: appPaths.customers.index(),
      search: filterCriteria
        ? filterCriteria
        : `excludedTemplateIds=${excludedTemplateIds}`,
    });
  }
  
  function handleClickContinue() {
    shouldFinishCampaignCreationOnUnmountRef.current = false;
    const initialFilters = UrlUtils.convertQueryStringToObject(searchParams.toString());
    let filters = { ...initialFilters };
    
    if (selectedAudienceRecommendation) {
      const audienceFilterCriteria =
        selectedAudienceRecommendation.filterCriteria;

      filters = UrlUtils.convertQueryStringToObject(audienceFilterCriteria);
    }
    const excludedTemplateIds = [
      selectedTemplate!.id,
      ...variants.map((variant) => variant.templateId),
    ].join(queryStringDelimiter);
    filters = {
      ...filters,
      [CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN]:
        minDaysSinceLastCampaign,
      [CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE]:
        minDaysSinceLastPurchase,
      excludedTemplateIds,
    };

    const queryString = UrlUtils.convertObjectToQueryString(filters);
    dispatch(setShowSelectCustomerRows(true));
    navigate({
      pathname: appPaths.customers.index(),
      search: `${queryString}`,
    });
  }

  function handleClickRecommendation(recommendation: AudienceRecommendation | null) {
    setSelectedAudienceRecommendation(recommendation);

    if (recommendation) {
      const filters = UrlUtils.convertQueryStringToObject(
        recommendation.filterCriteria
      );
      setMinDaysSinceLastCampaign(
        filters[CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_CAMPAIGN] || 14
      );
      setMinDaysSinceLastPurchase(
        filters[CustomerFiltersEnum.MIN_DAYS_SINCE_LAST_PURCHASE] || 21
      );
    } else {
      setMinDaysSinceLastCampaign(14);
      setMinDaysSinceLastPurchase(21);
    }
  }

  return (
    <Card>
      <CardHeader
        paddingBottom={0}
        display="flex"
        justifyContent={"space-between"}>
        <Heading size="md">Destinatários</Heading>
        {isFillingForm ? null : selectedCustomerIds.length === 0 ? (
          <Button
            onClick={handleClickSelectCustomers}
            isDisabled={!selectedTemplate}>
            Selecionar audiência
          </Button>
        ) : (
          <Flex alignItems={"center"}>
            <Text>{selectedCustomerIds.length} clientes selecionados</Text>
            <IconButton
              aria-label="Alterar clientes selecionados"
              variant={"ghost"}
              onClick={handleClickEditSelectedCustomers}
              icon={<FaEdit fontSize={16} />}
            />
          </Flex>
        )}
      </CardHeader>
      <CardBody>
        {filterCriteria && <TableFilters filterCriteria={filterCriteria} />}
        {isFillingForm && (
          <VStack gap={5}>
            <FormControl>
              <FormLabel fontSize={"sm"}>
                Recomendação inteligente da Revi (Inteligência artificial)
              </FormLabel>
              <AudienceRecommendationSelector
                audienceRecommendations={audienceRecommendations || []}
                onClickRecommendation={handleClickRecommendation}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontSize={"sm"}>
                Ocultar clientes que <u>receberam campanha</u> nos últimos
              </FormLabel>
              <InputNumber
                size="sm"
                rightAddon="dias"
                displayZero={true}
                value={minDaysSinceLastCampaign}
                onChange={(value) => setMinDaysSinceLastCampaign(value)}
                maxWidth="600px"
              />
            </FormControl>
            <FormControl>
              <FormLabel fontSize={"sm"}>
                Ocultar clientes que <u>compraram</u> nos últimos
              </FormLabel>
              <InputNumber
                size="sm"
                rightAddon="dias"
                displayZero={true}
                value={minDaysSinceLastPurchase}
                onChange={(value) => setMinDaysSinceLastPurchase(value)}
                maxWidth="600px"
              />
            </FormControl>
            <Flex width="100%" justifyContent={"space-between"}>
              <div />
              <Button
                onClick={handleClickContinue}
                color={colors.white}
                bgColor={colors.primary}>
                Continuar
              </Button>
            </Flex>
          </VStack>
        )}
      </CardBody>
    </Card>
  );
};

export default SectionCampaignRecipients;
